export { default } from "./Header";

export interface ILink {
	to: string;
	text: string;
	active: string;
}

export const links: ILink[] = [
	{ to: "/bookings", text: "Bookings", active: "booking" },
	{ to: "/chauffeurs", text: "Chauffeurs", active: "chauffeur" },
	{ to: "/vehicles", text: "Vehicles", active: "vehicle" },
];
