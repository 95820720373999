import "./Header.css";

import { links } from ".";
import { useEffect } from "react";
import logo from "assets/logo-light.png";
import { Container, Grid } from "@mui/material";
import AuthService from "services/auth.service";
import useEffectOnce from "hooks/useEffectOnce";
import SocketService from "services/socket.service";
import { MODAL, modalActions } from "redux/slices/modal";
import OneSignalService from "services/onesignal.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import BasicMenu from "components/atoms/BasicMenu/BasicMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.auth.user);

	useEffectOnce(() => {
		if (!user) return;

		AuthService.splash();
		OneSignalService.connect(user._id);
		SocketService.connect(user);
	});

	useEffect(() => {
		if (!user || user?.passwordChangeAt) return;

		dispatch(
			modalActions.openModal({
				type: MODAL.CHANGE_PASSWORD,
				data: "",
				width: "500px",
			})
		);
	}, [user, dispatch]);

	if (
		!user ||
		pathname === "/" ||
		pathname === "/password-otp" ||
		pathname === "/reset-password" ||
		pathname === "/forgot-password"
	)
		return <></>;

	return (
		<header>
			<div className="navbar">
				<Container>
					<Grid container alignItems="center">
						<Grid item xs={12} md={3}>
							<Link to="/bookings">
								<div className="logo">
									<img src={logo} alt="logo" loading="lazy" />
								</div>
							</Link>
						</Grid>
						<Grid item xs={12} md={9}>
							<ul>
								{links.map(({ to, text, active }, i) => (
									<li key={i}>
										<Link
											to={to}
											className={pathname.includes(active) ? "active" : ""}
										>
											{text}
										</Link>
									</li>
								))}
								<li>
									<div style={{ padding: "10px 0" }}>
										<BasicMenu
											text={
												user.first_name.charAt() + user.last_name.charAt()
											}
											list={[
												{
													text: user.first_name + " " + user.last_name,
													onClick: () => navigate("/profile"),
												},
												{
													text: "Logout",
													onClick: () => AuthService.logout(),
												},
											]}
										/>
									</div>
								</li>
							</ul>
						</Grid>
					</Grid>
				</Container>
			</div>
		</header>
	);
}
