import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "../Button";
import { Avatar } from "@mui/material";
import { useAppSelector } from "redux/hooks";

export default function BasicMenu({ text, list }: any) {
	const user = useAppSelector((state) => state.auth.user);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClose = () => setAnchorEl(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
		setAnchorEl(event.currentTarget);

	return (
		<div>
			<Button
				// id="basic-button"
				aria-label="menu"
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : "false"}
				onClick={handleClick}
				variant="text"
				sx={{
					color: "#ffffff",
					fontSize: "18px",
					textTransform: "capitalize",
					lineHeight: "1",
					verticalAlign: "unset",
					minWidth: "initial",
					padding: "0",
					"&:hover": {
						color: "#f5d312",
					},
				}}
			>
				{user.profile_pic.url ? (
					<div className="profile-img">
						<img
							loading="lazy"
							width={"38px"}
							height={"38px"}
							style={{ padding: "0" }}
							src={user.profile_pic.url}
							alt={`${user.first_name} ${user.last_name}`}
						/>
					</div>
				) : (
					<Avatar
						sx={{
							backgroundColor: "#fafafa",
							color: "#000000",
							fontSize: "16px",
						}}
					>
						{text}
					</Avatar>
				)}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{list.map((item: any, index: any) => (
					<MenuItem
						onClick={() => {
							item.onClick?.();
							handleClose();
						}}
						key={index}
					>
						{item.text}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
